import { Flex, Heading, Image, Link as ChakraLink, VStack } from '@chakra-ui/react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import { ButtonSize, CustomButton } from '~/components/layout/CustomButton'

const Join = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <Image
        h={{ base: '300px', md: '500px', '2xl': '650px' }}
        src="/assets/reflection-sub.png"
        pos="absolute"
        right={0}
        bottom={{ base: 310, md: 140, lg: 180, xl: '15%', '2xl': '20%' }}
        zIndex={-1}
      />

      <VStack mt={{ base: 56, sm: 48, md: 48, lg: 10, '2xl': 0 }}>
        <Flex
          direction="column"
          justify="center"
          align="center"
          position="absolute"
          bottom={{ base: 190, md: 350, lg: '15%', xl: '20%', '2xl': '22%' }}
        >
          <Heading
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl', '2xl': '7xl' }}
            maxW="70%"
            textAlign="center"
            fontWeight={700}
            lineHeight={{ base: '48px', md: '72px' }}
            bgGradient="linear(to-b, #EBEBEB, #CCCCCC)"
            bgClip="text"
            mb={6}
          >
            {t('join.title')}
          </Heading>
          <Link href="/dashboard" passHref>
            <ChakraLink _hover={{ textDecoration: 'none' }}>
              <CustomButton size={ButtonSize.LARGE}>{t('join.launchApp')}</CustomButton>
            </ChakraLink>
          </Link>
        </Flex>
        <Image src="/assets/city.png" h={{ base: '337px', md: 'auto' }} objectFit="cover" />
      </VStack>
    </>
  )
}

export default Join
