import { Container, Heading, Stack } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import SocialBox, { SocialType } from './SocialBox'

const Socials = () => {
  const { t } = useTranslation('home')

  return (
    <Container maxW="container.xl">
      <Heading
        textAlign="center"
        fontSize="40px"
        fontWeight={700}
        lineHeight="48px"
        mb={0}
        bgGradient="linear(to-b, #EBEBEB, #CCCCCC)"
        bgClip="text"
        pt={{ base: 10, md: 64 }}
        pb={12}
      >
        {t('socials.title')}
      </Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify="center"
        bgImage="/assets/vector-socials.svg"
        backgroundSize="70%"
        backgroundPosition="center"
      >
        <SocialBox social={SocialType.TWITTER} />
        <SocialBox social={SocialType.DISCORD} />
      </Stack>
    </Container>
  )
}

export default Socials
