import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { Box, Heading, Image, Link as ChakraLink, Text, VStack } from '@chakra-ui/react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import { ButtonSize, CustomButton } from '../layout/CustomButton'

export enum SocialType {
  TWITTER = 'Twitter',
  DISCORD = 'Discord',
}

const SocialBox = ({ social }: { social: SocialType }) => {
  const { t } = useTranslation('home')

  const SOCIAL_TYPE = {
    [SocialType.DISCORD]: {
      image: 'assets/discord.svg',
      heading: t('socials.discord.description'),
      buttonText: t('socials.discord.button'),
      icon: <FaDiscord size="20px" />,
      url: 'https://discord.gg/kfyNmmbvhJ',
    },
    [SocialType.TWITTER]: {
      image: 'assets/twitter.svg',
      heading: t('socials.twitter.description'),
      buttonText: t('socials.twitter.button'),
      icon: <FaTwitter size="20px" />,
      url: 'https://twitter.com/DeeprFinance',
    },
  }

  return (
    <Box
      maxW={{ base: '100%', md: '50%' }}
      borderRadius="3xl"
      border="1px solid #FFFFFF12"
      minH={{ base: '272px', md: '376px' }}
    >
      <Box bgImage="/assets/noise-texture.png" h="full">
        <VStack
          borderRadius="3xl"
          bgGradient="linear-gradient(118.15deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%)"
          px={{ base: 10, md: 20 }}
          py={{ base: 10, md: 16 }}
          justify="space-between"
          h="full"
        >
          <Image h={20} src={SOCIAL_TYPE[social].image} />
          <Heading fontSize="24px" lineHeight="32px" textAlign="center" py={8}>
            {SOCIAL_TYPE[social].heading}
          </Heading>
          <Link href={SOCIAL_TYPE[social].url} passHref>
            <ChakraLink _hover={{ textDecoration: 'none' }}>
              <CustomButton w="215px" size={ButtonSize.LARGE}>
                {SOCIAL_TYPE[social].icon}
                <Text ml={2}>{SOCIAL_TYPE[social].buttonText}</Text>
              </CustomButton>
            </ChakraLink>
          </Link>
        </VStack>
      </Box>
    </Box>
  )
}

export default SocialBox
