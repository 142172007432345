import { Box, GridItem, Heading, Image, Link as ChakraLink, Text, VStack } from '@chakra-ui/react'
import Link from 'next/link'

import { theme } from '~/styles/theme'

interface Props {
  title: string
  description: string
  span?: number
  imgSource: string
  inverted?: boolean
  url?: string
}

const FeaturesItem = ({ title, description, span, imgSource, inverted, url }: Props) => {
  return (
    <GridItem colSpan={span} h={{ base: '100%', md: '420px' }}>
      <Box borderRadius="3xl" border="1px solid #FFFFFF12" h="100%">
        <Box bgImage="/assets/noise-texture.png" h="full">
          <Box
            borderRadius="3xl"
            bgGradient="linear-gradient(118.15deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%)"
            h="100%"
          >
            <VStack p={{ base: 7, md: 10 }} justify="space-between" h="100%">
              {!inverted && <Image src={imgSource} pb={{ base: 3, md: 6 }} />}
              <VStack>
                <Heading fontSize="2xl" textAlign="center">
                  {title}
                </Heading>
                {url ? (
                  <Link href={url} passHref>
                    <ChakraLink
                      _hover={{
                        textDecoration: 'none',
                      }}
                    >
                      <Text
                        transition="0.5s"
                        _hover={{
                          background:
                            'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), linear-gradient(93.74deg, #6153CC 3.56%, #795EC1 97.35%)',
                          bgClip: 'text',
                        }}
                        bgGradient="linear(to-r, #6153CC, #795EC1)"
                        bgClip="text"
                        textAlign="center"
                        fontWeight={600}
                        borderBottom="1px solid"
                        style={{ borderImage: 'linear-gradient(90deg, #6153CC 50%, #795EC1 100%) 1' }}
                      >
                        {description}
                      </Text>
                    </ChakraLink>
                  </Link>
                ) : (
                  <Text color={theme.colors.text.secondary} textAlign="center">
                    {description}
                  </Text>
                )}
              </VStack>
              {inverted && <Image src={imgSource} pt={6} />}
            </VStack>
          </Box>
        </Box>
      </Box>
    </GridItem>
  )
}

export default FeaturesItem
