import { Box } from '@chakra-ui/react'
import { GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

import Features from '~/components/home/Features'
import Hero from '~/components/home/Hero'
import Join from '~/components/home/Join'
import Socials from '~/components/home/Socials'
import { theme } from '~/styles/theme'

const Home = () => {
  return (
    <Box bgColor={theme.colors.background.primary}>
      <Hero />
      <Box zIndex="1" position="relative">
        <Features />
        <Socials />
        <Join />
      </Box>
    </Box>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(locale && (await serverSideTranslations(locale, ['layout', 'home', 'toast', 'dashboard']))),
  },
})

export default Home
