import { Box, Container, Flex, Heading, Image, Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

import { theme } from '~/styles/theme'
import { ButtonSize, ButtonVariant, CustomButton } from '../layout/CustomButton'

const Hero = () => {
  const { t } = useTranslation('home')

  return (
    <Container height="100%" maxW="container.xl" zIndex="1" position="relative" pb={{ base: '64px', md: '0' }}>
      <Image
        h={{ base: '300px', md: '500px', '2xl': '650px' }}
        src="/assets/no-reflection-sub.png"
        pos="absolute"
        left={{ base: -20, md: -145, xl: '-15%', '2xl': '-22%' }}
      />
      <Flex
        minH={{ base: '120', md: '544', '2xl': '762' }}
        pt={{ base: 20, md: '0' }}
        flexDir="column"
        alignItems={{ base: 'center', md: 'center' }}
        justifyContent="center"
      >
        <Heading
          fontSize={{ base: '4xl', md: '5xl', '2xl': '7xl' }}
          maxW={{ base: '80%', md: '70%' }}
          textAlign="center"
          fontWeight={700}
          lineHeight={{ base: '48px', md: '72px', '2xl': '92px' }}
          mb={0}
          bgGradient="linear(to-b, #EBEBEB, #CCCCCC)"
          bgClip="text"
          whiteSpace="pre-line"
        >
          {t('hero.titleA')} {'\n'}
          <Text color="degen.D" display="inline">
            D
          </Text>
          <Text color="degen.E" display="inline">
            e
          </Text>
          <Text color="degen.G" display="inline">
            g
          </Text>
          <Text color="degen.E2" display="inline">
            e
          </Text>
          <Text color="degen.N" display="inline">
            n
          </Text>
          tralized Lending
        </Heading>
        <Box maxW="580" mt={4}>
          <Text color={theme.colors.text.secondary} textAlign="center" maxW={{ base: '300px', md: '500px' }}>
            Testing memecoin lending on ShimmerEVM
          </Text>
        </Box>
        <Stack direction={{ base: 'column', md: 'row' }} mt={10} w={{ base: 'full', md: 'auto' }} spacing="4">
          <Link href="/dashboard" passHref>
            <ChakraLink _hover={{ textDecoration: 'none' }}>
              <CustomButton size={ButtonSize.LARGE} w={{ base: 'full', md: 'auto' }}>
                {t('hero.getStarted')}
              </CustomButton>
            </ChakraLink>
          </Link>
          <Link href="https://medium.com/@Deepr.Finance/announcing-deepr-finance-28050e2dd4e" passHref>
            <ChakraLink _hover={{ textDecoration: 'none' }}>
              <CustomButton size={ButtonSize.LARGE} variant={ButtonVariant.OUTLINE} w={{ base: 'full', md: 'auto' }}>
                {t('hero.learnMore')}
              </CustomButton>
            </ChakraLink>
          </Link>
        </Stack>
      </Flex>
    </Container>
  )
}

export default Hero
