import { Box, Container, Grid } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'

import { theme } from '~/styles/theme'
import FeaturesCarousel from './FeaturesCarousel'
import FeaturesItem from './FeaturesItem'

const Features = () => {
  const { t } = useTranslation('home')

  return (
    <Container
      maxW="container.xl"
      bgGradient={theme.colors.background.primaryGradient}
      bgImage="/assets/vector-features.svg"
    >
      <Grid
        display={{ base: 'none', md: 'grid' }}
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(3, 1fr)"
        gap={6}
      >
        <FeaturesItem
          title={t('cryptoInvestors.features.lendAndBorrow.title')}
          description={t('cryptoInvestors.features.lendAndBorrow.description')}
          span={2}
          imgSource="/assets/markets-preview.png"
          inverted
        />
        <FeaturesItem
          title={t('cryptoInvestors.features.reliable.title')}
          description={t('cryptoInvestors.features.reliable.description')}
          span={1}
          imgSource="/assets/shield.svg"
          url="https://medium.com/@Deepr.Finance/deepr-protocol-completes-successful-audit-by-hashex-70b10304031f"
        />
        <FeaturesItem
          title={t('cryptoInvestors.features.fast.title')}
          description={t('cryptoInvestors.features.fast.description')}
          span={1}
          imgSource="/assets/fast.svg"
        />
        <FeaturesItem
          title={t('cryptoInvestors.features.streamlined.title')}
          description={t('cryptoInvestors.features.streamlined.description')}
          span={2}
          imgSource="/assets/lighthouse-preview.png"
        />
      </Grid>
      <Box display={{ base: 'contents', md: 'none' }} h="100%">
        <FeaturesCarousel />
      </Box>
    </Container>
  )
}

export default Features
