import { Box, Heading, Image, Link as ChakraLink, Text, VStack } from '@chakra-ui/react'
import Link from 'next/link'

import { theme } from '~/styles/theme'

interface Props {
  title: string
  description: string
  span?: number
  imgSource: string
  inverted?: boolean
  url?: string
}

const AbsoluteFeaturesItem = ({ title, description, imgSource, inverted, url }: Props) => {
  return (
    <Box borderRadius="3xl" border="1px solid #FFFFFF12" h="100%" overflow="hidden">
      <Box bgImage="/assets/noise-texture.png" h="full">
        <Box
          borderRadius="3xl"
          bgGradient="linear-gradient(118.15deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%)"
          h="100%"
        >
          {!inverted && <Image objectFit="cover" position="relative" left={8} top={12} src={imgSource} />}
          <VStack p={{ base: 7, md: 10 }} justify="flex-start" h="100%" pt={inverted ? 7 : 24}>
            <VStack>
              <Heading fontSize="2xl" textAlign="center">
                {title}
              </Heading>
              {url ? (
                <Link href={url} passHref>
                  <ChakraLink
                    _hover={{
                      textDecoration: 'none',
                    }}
                  >
                    <Text
                      transition="0.5s"
                      _hover={{
                        background:
                          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), linear-gradient(93.74deg, #6153CC 3.56%, #795EC1 97.35%)',
                        bgClip: 'text',
                      }}
                      bgGradient="linear(to-r, #6153CC, #795EC1)"
                      bgClip="text"
                      textAlign="center"
                      fontWeight={600}
                      borderBottom="1px solid"
                      style={{ borderImage: 'linear-gradient(90deg, #6153CC 50%, #795EC1 100%) 1' }}
                    >
                      {description}
                    </Text>
                  </ChakraLink>
                </Link>
              ) : (
                <Text color={theme.colors.text.secondary} textAlign="center">
                  {description}
                </Text>
              )}
            </VStack>
            {inverted && <Image objectFit="fill" src={imgSource} position="absolute" left={8} top={40} />}
          </VStack>
        </Box>
      </Box>
    </Box>
  )
}

export default AbsoluteFeaturesItem
